/* eslint-disable */
import {HomeSharp} from '@material-ui/icons';
import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'

import Withdrawl from "./Components/Withdrawl";
import Login from "./Components/Login";
import Forgot from "./Components/Forgot";
import Resetmpin from "./Components/Resetmpin";
import Profile from "./Components/Pages/Profile";
import Leads from "./Components/Pages/Leads";
import Securities from "./Components/Pages/Securities";
import Faq from "./Components/Pages/Faq";
import Dashboard from "./Components/Pages/Dashboard";
import TermsAndConditions from "./Components/Pages/TermsAndConditions";
import Error from "./Components/Error";


export const Routes = () => {
    return (
        <div>
            <Switch>
                <Route exact path="/" component={Login}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/dashboard" component={Dashboard}/>
                <Route exact path="/withdrawal" component={Withdrawl}/>
                <Route exact path="/profile" component={Profile}/>
                <Route exact path="/leads" component={Leads}/>
                <Route exact path="/transactions" component={Securities}/>
                <Route exact path="/faq" component={Faq}/>
                <Route exact path="/forgot" component={Forgot}/>
                <Route exact path="/terms-conditions" component={TermsAndConditions}/>
                <Route exact path="/resetmpin" component={Resetmpin}/>
                <Route component={Error}/>
            </Switch>
        </div>
    )
}