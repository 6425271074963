/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import "../Sidebar.css";
import Header from "../Header";
import { getLeadTransaction } from "../../Redux/ApiCall/action";

import Side from "../Side";
// import {resolve} from "q";
// import ProfileMainImg from "../../img/profile-main.png";
// import pointer from "../../img/pointer.svg";
// import normalStar from "../../img/normal-star.svg";
// import bronzeStar from "../../img/broze-star.svg";
// import back from "../../img/back.svg";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Dropdown from "react-bootstrap/Dropdown";
import filter from "../../img/filter.svg";
import CalenderIcon from "@material-ui/icons/Today";
import Cookies from "universal-cookie";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import moment from 'moment';

const cookies = new Cookies();

const Securities = (props) => {
  const [transactionData, setTransactionData] = useState([]);
  const [status, setStatus] = useState("");
  const [enddate, setEnddate] = useState("");
  const [filteroption, setFilteroption] = useState("All Time");
  const [checkboxSuccess, setCheckboxSuccess] = useState(false);
  const [checkboxPending, setCheckboxPending] = useState(false);
  const [enabledCheckBox, setEnabledCheckBox] = useState(false);
  const [filterdata, setFilterdata] = useState("");
  const [success, setSuccess] = useState("");
  const [loader, setLoader] = useState(true);
  const [pending, setPending] = useState("");
  const [common,setCommon]=useState("");


  const handleFilter = (e) => {
    setEnddate(e);
    if(success==="C" && pending===""){
      setCommon("C")
      console.log("success")
      
      }
      if(success==="" && pending==="P")
      {
        setCommon("P");
        console.log("pending")
       
      }
      if(success==="" && pending==="")
      {
        setCommon("");
        console.log("blank")
      }
  };

  const handleCheckboxSuccess = () => {
    setLoader(true);
    setSuccess("C");
    let token = cookies.get("token");
    let data = {
      end_date: filterdata.end_date,
      start_date: filterdata.start_date,
      txn_status_filter: "C",
    };
    if (checkboxSuccess === true) {
      setSuccess("");
      data = {
        end_date: filterdata.end_date,
        start_date: filterdata.start_date,
        txn_status_filter: "",
      };
    }
    setCheckboxSuccess((enabledCheckBox) => !enabledCheckBox);
    console.log("data1",data)
    props.getLeadTransaction(data, token).then((res) => {
      setLoader(false);
      setTransactionData(res?.data.data);
      setStatus(res?.data.data.txn_status);
    });
  };
  const handleCheckboxPending = () => {
    setPending("P");
    setLoader(true);
    let token = cookies.get("token");
    let data = {
      end_date: filterdata.end_date,
      start_date: filterdata.start_date,
      txn_status_filter: "P",
    };
    if (checkboxPending === true) {
      setPending("");
      data = {
        end_date: filterdata.end_date,
        start_date: filterdata.start_date,
        txn_status_filter: "",
      };
   
    }
    setCheckboxPending((enabledCheckBox) => !enabledCheckBox);
    console.log("data2",data)
    props.getLeadTransaction(data, token).then((res) => {
      setLoader(false);
      setTransactionData(res?.data.data);
      setStatus(res?.data.data.txn_status);
    });
  
  };



  var date = new Date().toISOString().slice(0, 10);
  var d = new Date();
  var previousYearDate = `${d.getFullYear()}-${1}-${1}`;
  var last_month = `${d.getFullYear()}-${d.getMonth()}-${1}`;
  var this_month = `${d.getFullYear()}-${d.getMonth() + 1}-${1}`;
  var lastpreviousYearDate = `${d.getFullYear() - 2}-${1}-${1}`;

  var now = new Date();
  var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  var lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
  var this_week = `${lastSunday.getFullYear()}-${
    lastSunday.getMonth() + 1
  }-${lastSunday.getDate()}`;
  var last_week = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
  var last_week_end = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
  useEffect(() => {
    const token = cookies.get("token");

    if (!cookies.get("token")) {
      props.history.push({ pathname: "/" });
      return;
    }

 

    if (enddate === this_month) {
      setLoader(true);
      var data = {
        end_date: date,
        start_date: enddate,
        txn_status_filter:common,
      };
      setFilteroption("This Month");
    }
    if (enddate === last_month) {
      setLoader(true);
      var data = {
        end_date: this_month,
        start_date: enddate,
        txn_status_filter: common,
      };
      setFilteroption("Last Month");
    }
    if (enddate === this_week) {
      setLoader(true);
      var data = {
        end_date: date,
        start_date: enddate,
        txn_status_filter: common,
      };
      setFilteroption("This Week");
    }
    if (enddate === last_week) {
      setLoader(true);
      var data = {
        end_date: last_week_end,
        start_date: enddate,
        txn_status_filter: common,
      };
      setFilteroption("Last Week");
    }
    if (enddate === previousYearDate) {
      setLoader(true);
      var data = {
        end_date: date,
        start_date: enddate,
        txn_status_filter: common,
      };
      setFilteroption("This Year");
    }
    if (enddate === lastpreviousYearDate) {
      setLoader(true);
      var data = {
        end_date: previousYearDate,
        start_date: enddate,
        txn_status_filter:common,
      };
      setFilteroption("Last Year");
    }
    if (enddate === "") {
      setLoader(true);
      var data = { txn_status_filter: common };

      setFilteroption("All Time");
    }
    setFilterdata(data);
    console.log("data3",data,common)
    props
      .getLeadTransaction(data, token)
      .then((res) => {
        setLoader(false);
        setTransactionData(res?.data.data);
        setStatus(res?.data.data.txn_status);
      })
      .catch((error) => {
        let err = error.response.status;

        if (err === 401) {
          props.history.push("/");
        }
      });
  }, [enddate]);

  const products = transactionData;

  const columns = [
    {
      dataField: "created_at",
      text: "Date",
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell !== "object") {
          dateObj = new Date(cell);
        }
        return `${("0" + dateObj.getDate()).slice(-2)}/${(
          "0" +
          (dateObj.getMonth() + 1)
        ).slice(-2)}/${dateObj.getFullYear()}`;
      },
    },
    {
      dataField: "desc",
      text: " Notes",
      sort: true,
    },
    {
      dataField: "txn_status",
      text: "Payment Status",
      sort: true,
    },
    {
      dataField: "txn_type",
      text: " Txn Type",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
  ];
  console.log(filterdata.end_date,common, "datatra");
  return (
    <>
      <Header {...props} />
      <Side />
      <div className="wrapper">
        <div style={{ position: "absolute", top: 10, right: 20 }}>
          <Dropdown align="end" className="filterName">
            <Dropdown.Toggle variant="btn">
              <img
                className="pointer"
                src={filter}
                alt="filter"
                style={{ marginTop: -4 }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="filterStatus">
              <div className="filterHead">Filter By Status</div>
              <label htmlFor="success">
                <input
                  type="checkbox"
                  id="success"
                  onChange={handleCheckboxSuccess}
                  value="Monday"
                  disabled={checkboxPending}
                />{" "}
                Completed
              </label>
              <label htmlFor="pending">
                <input
                  type="checkbox"
                  id="pending"
                  onChange={handleCheckboxPending}
                  value="Monday"
                  disabled={checkboxSuccess}
                />{" "}
                Pending
              </label>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown onSelect={handleFilter} align="end" className="filterMonth">
            <Dropdown.Toggle variant="btn" className="monthFilter">
              <CalenderIcon className="iconColor" />
              {filteroption}
            </Dropdown.Toggle>

            <Dropdown.Menu onSelect={handleFilter} className="dropDownFont">
              <Dropdown.Item eventKey={this_month}>
                {" "}
                <CalenderIcon className="calanderIcon" /> This Month
              </Dropdown.Item>

              <Dropdown.Item eventKey={this_week}>
                <CalenderIcon className="calanderIcon" /> This Week
              </Dropdown.Item>
              <Dropdown.Item eventKey={last_week}>
                <CalenderIcon className="calanderIcon" /> Last Week
              </Dropdown.Item>
              <Dropdown.Item eventKey={last_month}>
                <CalenderIcon className="calanderIcon" /> Last Month
              </Dropdown.Item>
              <Dropdown.Item eventKey={previousYearDate}>
                <CalenderIcon className="calanderIcon" /> This Year
              </Dropdown.Item>
              <Dropdown.Item eventKey={lastpreviousYearDate}>
                <CalenderIcon className="calanderIcon" /> Last Year
              </Dropdown.Item>
              <Dropdown.Item eventKey="">
                <CalenderIcon className="calanderIcon" /> All Time
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="backPage">Your Transactions</div>
        <Card className="cardShadow widthFull tabularGrid">
          <Card.Body style={{ padding: 30 }}>
            {!loader ? (
              <div className="tabularData">
                <BootstrapTable
                  keyField="id"
                  data={products}
                  columns={columns}
                  pagination={paginationFactory()}
                />
              </div>
            ) : (
              <Skeleton count={15} />
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { getLeadTransaction })(Securities);
