/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../Sidebar.css";
import Header from "../Header";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PersonIcon from "@material-ui/icons/Person";
import Modal from "react-bootstrap/Modal";
import {
  leadWallet,
  getRevenueLeads,
  getUserDetails,
  getUserProfie,
  getReferralCode,
  postFeedback,
} from "../../Redux/ApiCall/action";

import Side from "../Side";
import silverStar from "../../img/normal-star.svg";
import normalStar from "../../img/normal.svg";
import bronzeStar from "../../img/broze-star.svg";
import goldStar from "../../img/gold.svg";
import platinumStar from "../../img/platinum.svg";
import superStar from "../../img/superstar.svg";
import back from "../../img/back.svg";
import copy from "../../img/copy.svg";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import Cookies from "universal-cookie";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const cookies = new Cookies();

toast.configure();
const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 6000,
  limit: 1,
  closeButton: false,
};
const Profile = (props) => {
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState("");
  const [referallink, setReferallink] = useState("");
  const [profile, setProfile] = useState("");
  const [fname, setFname] = useState("");
  const [sname, setSname] = useState("");
  const [phone, setPhone] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [referalCode, setReferalCode] = useState("");
  const [feedbackbody, setFeedbackbody] = useState("");
  const [leads, setLeads] = useState();
  const [loader ,setLoader]=useState(true);
  const [loadercode,setLoaderrefcode]=useState(true)
  const [loaderamount,setLoaderamount]=useState(true);

  useEffect(() => {
    const token = cookies.get("token");
    // console.log("token",token)
    if (!cookies.get("token")) {
      props.history.push({ pathname: "/" });
      return;
    }

    props.getUserDetails(token).then((res) => {
      if(res){
        setLoader(false);
      }
      setFname(res.data.data[0].first_name);
      setSname(res.data.data[0].last_name);
      setReferallink(res.data.data[0].referral_link);
      setEmail(res.data.data[0].email);
      setPhone(res.data.data[0].phone_number);
      setCreateDate(res.data.data[0].created_at);
    })
    .catch((error) => {
      let err = error.response.status;
  
      if(err===401)
      {
        props.history.push("/")
      }});;

    props.getUserProfie(token).then((res) => {
      setProfile(res.data.presigned_url);
    });

    props.leadWallet(token).then((res) => {
      setLoaderamount(false)
      setAmount(res.data.data.balance);
    });
    let data = "";
    props.getRevenueLeads(data, token).then((res) => {
       setLeads(res.data.data.leads);
    });

    props.getReferralCode(token).then((res) => {
      setLoaderrefcode(false)
      setReferalCode(res.data.referral_code);
    });
  }, []);
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useState(null);
  const textAreaRefCode = useState(null);
  const [togglecode, setTogglecode] = useState(true);
  const [feedbackerr, setFeedbackerr] = useState("");
  const [errfeedback,setErrfeedback]=useState("");

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    setCopySuccess("Copied!");
    e.target.focus();
    if (togglecode) {
      toast.success("Copied!", { ...options });
      setTogglecode(false);
      setTimeout(() => setTogglecode(true), 6000);
    }
  }

  function copyToClipboardCode(e) {
    
    textAreaRefCode.current.select();
    document.execCommand("copy");
    setCopySuccess("Copied!");
    e.target.focus();
    if (togglecode) {
      toast.success("Copied!", { ...options });
      setTogglecode(false);
      setTimeout(() => setTogglecode(true), 6000);
    }
  }

  const postFeedback = (newRating) => {
    let star = Number(newRating);
    if(feedbackbody==="")
    {
      setErrfeedback("Kindly Write Something ");
      return;
    }
    if (!star) {
      setFeedbackerr("Kindly rate us !");
      return;
    }
    setFeedbackerr("");
    let token = cookies.get("token");

    let body = `Refer Feedback: ${feedbackbody}`;
    let data = {
      body,
      star,
    };
    props.postFeedback(token, data).then((res) => {
      if (res.status === 200) {
        toast.success(
          "Thank you so much for feedback. We really appreciate you taking the time out to share your experience with us!",
          { ...options }
        );
      }
    });
    handleClose();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Header {...props} />
      <Side {...props} />
      <div className="wrapper">
        <div className="backPage">
          <Link to="/dashboard" className="linkBack">
            {" "}
            <img
              className="pointer"
              src={back}
              alt="profile"
              style={{ marginTop: -4 }}
            />{" "}
            Back
          </Link>
        </div>
        <Card className="leftSection">
          <Card.Body style={{ textAlign: "center" }}>
            {profile ? (
              <img className="profile_Main_icon" src={profile} alt="profile" />
            ) : (
              <PersonIcon
                style={{
                  width: 130,
                  height: 130,
                  borderRadius: "50%",
                  color: "#33658A",
                }}
                className="profile_Main_icon"
              />
            )}
            {!loader ? (<>
            <Card.Title className="title">
              {fname} {sname}
            </Card.Title>
            <Card.Text className="address" style={{ fontSize: "80%" }}>
              {email}
            </Card.Text></>):<Skeleton count={2} height={15} />}
            <div className="textInfo">
              <label>Phone Number</label>
             {!loader? (<p>+91 {phone}</p>):<Skeleton/>}
            </div>
            <div className="textInfo">
              <label>Account Opened on</label>
              {!loader?(
              <p> {moment(createDate).format(" MMMM Do YYYY")}</p>):<Skeleton height={20}/>}
            </div>
            <div className="textInfoGray">
              <label>Total Earnings so far</label>
            {!loaderamount ? ( <p>₹ {amount}</p> ): <SkeletonTheme color="#33658A" highlightColor="#ffffff"><Skeleton /></SkeletonTheme>}
            </div>
          </Card.Body>
        </Card>
        <div className="rightSection">
          <Card className="cardShadow">
            <Card.Body className="relative">
              <Card.Text className="labelReferral">Referral Link</Card.Text>
              {!loader ? (
              <input
                className="inputLarge"
                ref={textAreaRef}
                value={referallink}
              />): <Skeleton height={60} radius={20}/>}
              <img
                className="copy"
                onClick={copyToClipboard}
                src={copy}
                alt="Copy"
              />
            </Card.Body>
          </Card>
          <Card className="cardShadow">
            <Card.Body className="relative">
              <Card.Text className="labelReferral">Referral Code</Card.Text>
            {!loadercode ? ( <input
                className="inputLarge"
                ref={textAreaRefCode}
                value={referalCode}
                placeholder="Referraal code"
              />): <Skeleton height={60} radius={20}/>}
              <img
                className="copy"
                onClick={copyToClipboardCode}
                src={copy}
                alt="copy"
              />
            </Card.Body>
          </Card>

          <Card className="cardShadow">
            <Card.Body>
              <Card.Text className="labelReferral">
                Your Performance level
              </Card.Text>
              <div className="star-wrapper">
                <div className="star-content">
                
                  { (leads>2 && leads <5) ? (
                    <>
                      <img className="pointer" src={bronzeStar} alt="profile" />{" "}
                    </>
                  ) : (
                    <img className="pointer" src={normalStar} alt="profile" />
                  )}
                  <h5>Bronze</h5>
                  <span>(3 Successful Shares)</span>
                </div>
                <div className="star-content">
                { (leads>4 && leads <10) ? (
                    <>
                      <img className="pointer"   src={silverStar} alt="profile" />{" "}
                    </>
                  ) : (
                    <img className="pointer" src={normalStar} alt="profile" />
                  )}
                  <h5>Silver</h5>
                  <span>(5 Successful Shares)</span>
                </div>
                <div className="star-content">
                { (leads>9 && leads <20) ? (
                    <>
                      <img className="pointer"   src={goldStar} alt="profile" />{" "}
                    </>
                  ) : (
                    <img className="pointer" src={normalStar} alt="profile" />
                  )}
                  <h5>Gold</h5>
                  <span>(10 Successful Shares)</span>
                </div>
                <div className="star-content">
                { (leads>19 && leads <50) ? (
                    <>
                      <img className="pointer"   src={platinumStar} alt="profile" />{" "}
                    </>
                  ) : (
                    <img className="pointer" src={normalStar} alt="profile" />
                  )}
                  <h5>Platinum</h5>
                  <span>(20 Successful Shares)</span>
                </div>
                <div className="star-content">
                { leads>49 ? (
                    <>
                      <img className="pointer"   src={superStar} alt="profile" />{" "}
                    </>
                  ) : (
                    <img className="pointer" src={normalStar} alt="profile" />
                  )}
                  <h5>Superstar</h5>
                  <span>(50+ Successful Shares)</span>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="cardShadow contentText" style={{ margin: 0 }}>
            <Card.Body>
              <Card.Text className="labelReferral">
                Share Your Feedback
              </Card.Text>
              <div className="clearfix">
                <div className="alignRight">
                  <a href="#" className="green-link" onClick={handleShow}>
                    Share your feedback
                  </a>
                </div>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header style={{ border: 0 }}>
                    <Modal.Title className="headFeedback">
                      Share your Feedback or suggestions with us based on your
                      Experience
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ border: 0 }}>
                    <ul className="bulletNumber">
                      <li>
                        What new feature(s) would you like to see or would you
                        like to modify any existing feature on the Dashboard? If
                        this feature is added/modified, what problem would that
                        solve for you?
                        <input
                          className="inputFieldFeedback"
                          placeholder="Share your thoughts"
                          onChange={(e) => {
                           
                            setErrfeedback("");
                            setFeedbackbody(e.target.value);
                          }}
                          value={feedbackbody}
                        />
                       {errfeedback ? <span style={{color:"red"}}>{errfeedback}</span> :null}
                      </li>
                      <li>
                        How would you rate our new referral portal on a scale of
                        1 to 5?
                      </li>
                      <ReactStars
                        style={{ top: "300%" }}
                        count={5}
                        onChange={postFeedback}
                        size={34}
                        activeColor="#ffd700"
                      />
                      <span style={{ color: "red" }}>{feedbackerr}</span>
                    </ul>
                  </Modal.Body>
                  <Modal.Footer style={{ border: 0 }}>
                    <Button
                      variant="secondary floatRight disabledBtn"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary btn-green floatRight"
                      style={{ marginBottom: 3, width: 110 }}
                      onClick={postFeedback}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  leadWallet,
  getUserDetails,
  getUserProfie,
  getReferralCode,
  postFeedback,
  getRevenueLeads,
})(Profile);
