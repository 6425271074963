import axios from "axios";
import { API_ENDPOINT } from "../../apiConstant";
import { USER_TOKEN, PHONE_NUMBER } from "../type";
import Cookies from 'universal-cookie';
 
const cookies = new Cookies();

export const saveUserToken = (token) => {
  return (dispatch) => {
  
    cookies.set("token",token.token)
    dispatch({
      type: USER_TOKEN,
      payload: token,

    });
  };
};

export const savePhoneNumber = (phone_number) => {
  return (dispatch) => {
    dispatch({
      type: PHONE_NUMBER,
      payload: phone_number,
    });
  };
};

export const signInUser = (data) => {
  let url = `${API_ENDPOINT}/api/authentication/authenticate_mpin/`;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((res) => {
          saveUserToken(dispatch, res.data.data);
          
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const sendOtp = (data) => {
  let url = `${API_ENDPOINT}/api/send_otp_phone/exist_user/`;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((res) => {
          
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const verifyOtp = (data) => {
  let url = `${API_ENDPOINT}/api/verify_otp_phone/`;
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((res) => {
          
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const updateMpin = (data, token) => {
  let url = `${API_ENDPOINT}/api/authentication/create_or_update_mpin/`;
  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res) => {
          
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const leadWallet = (token) => {
 
  let url = `${API_ENDPOINT}/api/referral/referral_lead_wallet/`;
  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
         
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const getUserDetails = (token) => {
 
  let url = `${API_ENDPOINT}/api/user_details/basic_details/`;
  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const getUserProfie = (token) => {
 
  let url = `${API_ENDPOINT}/api/presigned_url_user_image/`;
  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const createTransaction = (data, token) => {
  let url = `${API_ENDPOINT}/api/referral/referral_transction_create/`;
  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res) => {
          
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const getLeads = (data, token) => {
  let url = `${API_ENDPOINT}/api/referral/referral_lead/`

  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res) => {
       
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const getLeadTransaction = (data, token) => {
  let url = `${API_ENDPOINT}/api/referral/referral_lead_transction/`

  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res) => {
          
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};



export const getReferralCode = (token) => {

  let url = `${API_ENDPOINT}/api/referral/referral_code/`;
  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {

          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};


export const getRevenueLeads = (data, token) => {
  let url = `${API_ENDPOINT}/api/referral/referral_revenue_leads/`
  
  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res) => {
          
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};

export const getBankDetails = (token) => {

    let url = `${API_ENDPOINT}/api/user_details/bank_details/`;
    let config = {
        headers: {
            Authorization: "Token " + token,
            "Content-Type": "application/json",
        },
    };

    return (dispatch) =>
        new Promise((resolve, reject) => {
            axios
                .get(url, config)
                .then((res) => {

                    return resolve(res);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
};

export const postFeedback = (token, data) => {
  let url = `${API_ENDPOINT}/api/feedback/`;
  let config = {
    headers: {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    },
  };

  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res) => {
          
          return resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
};