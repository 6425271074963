/* eslint-disable*/
import { React, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Card, Form, Button } from "react-bootstrap";
import { sendOtp } from "../Redux/ApiCall/action";
import logo from "../img/Logo.png";
import { savePhoneNumber } from "../Redux/ApiCall/action";
import Confirmotp from "./Confirmotp";
import "./Sidebar.css";

const Forgot = (props) => {
  const [phone, setPhone] = useState(props?.user?.phone_number);
  const [errorPhone, setErrorPhone] = useState("");
  const [otppage, setOtppage] = useState(false);
  const [userexist,setUserexist]=useState("");

  const handleOtp = () => {
    if (phone?.length === 10) {
      let phone_number = phone;
      let data = {
        phone_number,
      };
      props.savePhoneNumber({
        phone_number,
      });
      props
        .sendOtp(data)
        .then((res) => {
          if (res.status === 200) {
            setOtppage(true);
          }
        })
        .catch((error) => {
          let err = error.response.data.error;

          setUserexist(err);
        });
    } else {
      setErrorPhone("Phone must be 10 digits");
      return;
    }
  };

  return (
    <>
      {otppage ? (
        <Confirmotp {...props} />
      ) : (
          <div className='blue-bg'>
            <Card className='loginScreen'>
              <Card.Body className='loginScreenPadding'>
                <Link to="/">
                  <Card.Img
                      style={{
                        width:'auto',
                        height: 37,
                        margin: "auto",
                      }}
                      src={logo}
                      alt="Paymeindia"
                  />
                </Link>
                <Card.Title  className='labelTitleBox'>
                  Forgot Password
                </Card.Title>
                <label htmlFor="userid" className='labelText'>
                  Enter Phone Number
                </label>
                <input
                  className="inputField"
                  id="userid"
                  name="uesrid"
                  type="text"
                  placeholder="Enter Phone Number"
                  value={phone}
                  onChange={(e) => {
                    if(e.target.value.match(/^[0-9]+$/)){ 
                      setPhone(e.target.value.slice(0,10));
                    }
                  else if (e.target.value.length===0)
                {
                  setPhone(e.target.value);
                }
                   
                    setUserexist("");
                    setErrorPhone("");
                  }}
                />
                {errorPhone ? (
                  <span className='errorMessage' >{errorPhone}</span>
                ) : null}
               {userexist ? <span style={{color:"red"}}>{userexist}</span>:null}
                <Button className="btnNormal" onClick={handleOtp}>
                  Send OTP
                </Button>
              </Card.Body>
            </Card>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { sendOtp, savePhoneNumber })(Forgot);
