/* eslint-disable*/
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Container, Row, Col, Card, Button} from "react-bootstrap";
import "../Sidebar.css";
import Header from "../Header";
import {leadWallet, createTransaction} from "../../Redux/ApiCall/action";

import Side from "../Side";
import {resolve} from "q";

const TermsAndConditions = (props) => {
  


    return (
        <>
            <Header {...props} />
            <Side {...props}/>
            <div className="wrapper">
                <div className="backPage">
                    Terms & Conditions
                </div>


                        <Card
                            className="cardShadow contentText"
                            style={{minHeight: 260, margin: 0, height: '100%',marginBottom:30}}>

                            <Card.Body style={{padding: 30, }}>
                             
                                <ul className='bulletPoint'>
                                    <li>
                                        The minimum withdrawal amount must be Rs. 50. There
                                        is no maximum limit.
                                    </li>

                                    <li>
                                        To receive the cash rewards, The referee’s account
                                        must be ‘verified’ on PayMe App.
                                    </li>

                                    <li>
                                        Withdrawal balance would be updated within 24 hours
                                        of successful verification of referee’s account.
                                    </li>

                                    <li>
                                        To refer someone, it is compulsory to sign up with
                                        PayMe India app.
                                    </li>

                                 

                                    <li>
                                        You can transfer the cash rewards to your bank
                                        account only once it is verified by PayMe India.
                                    </li>

                                    <li>
                                        Once you initiate the transfer request to your
                                        account, the request will be opened, and the status would be
                                        ‘Yes’. The requested fund will be credited to your banking
                                        account with 24 hours.
                                    </li>

                                    <li>
                                        You can request only one withdrawal at time.
                                    </li>

                                    <li>
                                        Once the withdrawal request is processed by PayMe,
                                        the open request status will be ‘NO’ and you can you again
                                        initiate the request of withdrawal.
                                    </li>
                                    <li>In order to withdraw the amount, kyc and bank details need to be submitted and verified by us (verification takes maximum 24 working hours, hence we recommend using eKYC option).</li>
                                </ul>
                            </Card.Body>
                        </Card>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, {leadWallet, createTransaction})(
    TermsAndConditions
);
