/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../Sidebar.css";
import Header from "../Header";
import { leadWallet, getUserDetails } from "../../Redux/ApiCall/action";

import Side from "../Side";

import faqs from "../../img/faqs.svg";

import Accordion from "react-bootstrap/Accordion";
import Cookies from "universal-cookie";

const cookies = new Cookies();
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';

const Faq = (props) => {
  const [fname, setFname] = useState("");
  const [sname, setSname] = useState("");

  useEffect(() => {
    const token = cookies.get("token");

    if (!cookies.get("token")) {
      props.history.push({ pathname: "/" });
      return;
    }

    props
      .getUserDetails(token)
      .then((res) => {
        setFname(res.data.data[0].first_name);
        setSname(res.data.data[0].last_name);
      })
      .catch((error) => {
        let err = error.response.status;

        if (err === 401) {
          props.history.push("/");
        }
      });
  }, []);

  // const decoratedOnClick = useAccordionButton(eventKey, onClick);
  return (
    <>
      <Header {...props} />
      <Side />
      <div className="wrapper">
        <div className="clearfix widthFull faqAccordion">
          <Card className="cardShadow widthFull">
            <Card.Body className="faqBox">
              <div className="floatLeft leftContent">
                <h4>
                  Welcome {fname} {sname}!
                </h4>
                <p>
                  Thank you for choosing PayMe India for your short-term loan
                  requirements and we welcome you to PayMe India’s newly
                  launched Enhanced Referral Program (PERP). Get cash rewards*
                  directly into your bank account by successfully referring your
                  friends/colleagues/family members who are eligible to take the
                  loan from Banks/NBFCs/Other institutions under regulatory
                  norms.
                </p>
                <p>
                  *Cash reward would be reflected in the ‘Earnings’ section once
                  referee’s profile is verified.
                </p>
                <p>
                  For any queries or help reach out to us on{" "}
                  <a >refer@paymeindia.in</a>
                </p>
              </div>
              <div className="floatRight rightContent alignRight">
                <img className="img" src={faqs} alt="profile" />
              </div>
            </Card.Body>
          </Card>
          <div className="clearfix head">Frequenty Asked Questions</div>
          <Accordion>
            <Accordion.Item eventKey="0" className="cardShadow">
              <Accordion.Header>
                What is PayMe Enhanced Refferal Program (PERP)?
              </Accordion.Header>
              <Accordion.Body>
                PayMe Enhanced Referral Program (PERP) is a referral program
                offered by PayMe India to its customers to provide rewards in
                terms of cash who refer PayMe India App to their
                friends/relatives who are eligible to take the loan from
                Banks/NBFCs/Other institutions under regulatory norms.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="cardShadow">
              <Accordion.Header>
                How can I become eligible for PERP?
              </Accordion.Header>
              <Accordion.Body>
                You need to sign up with PayMe India App to get eligible for
                PERP. App can easily be downloaded from Google Play Store / App
                store.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="cardShadow">
              <Accordion.Header>
                Is there any charges for become a part of PERP?
              </Accordion.Header>
              <Accordion.Body>No, its free.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="cardShadow">
              <Accordion.Header>
                How will I get my login id and password for PERP portal?
              </Accordion.Header>
              <Accordion.Body>
                You can use your registered mobile number and MPIN for login.
                This is the same credentials which you use to login the PayMe
                App.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="cardShadow">
              <Accordion.Header>
                How much cash rewards I will be getting if I refer PayMe App
                someone?
              </Accordion.Header>
              <Accordion.Body>
                You will get a cash reward Rs. 100 if you refer someone and
                his/her profile is verified by PayMe India?
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="cardShadow">
              <Accordion.Header>
                If my referee further refers someone then will I also get cash
                rewards?
              </Accordion.Header>
              <Accordion.Body>
                No. The cash rewards will be credited in your referee’s account
                who is acting like a referrer in this case.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className="cardShadow">
              <Accordion.Header>
                What is the minimum/maximum amount can I withdraw at a time?
              </Accordion.Header>
              <Accordion.Body>
                You can withdraw minimum Rs. 50 at a time, there is no maximum
                limit.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" className="cardShadow">
              <Accordion.Header>
                Can I make multiple withdrawals at a time?
              </Accordion.Header>
              <Accordion.Body>
                No, you can raise one withdrawal request at a time. Once it is
                successfully processed then you can raise it again.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8" className="cardShadow">
              <Accordion.Header>
                How Can I change my bank details?
              </Accordion.Header>
              <Accordion.Body>
                Go to PayMe India App > Profile > Change Bank account. Once it
                is verified by PayMe team.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9" className="cardShadow">
              <Accordion.Header>
                Can I change/edit my profile via PERP portal?
              </Accordion.Header>
              <Accordion.Body>
                No, you can only change your profile details (Including mobile
                number, email id, bank account details) etc. via PayMe India
                mobile app only. Once it is verified, it will automatically be
                reflected in PERP portal.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10" className="cardShadow">
              <Accordion.Header>
                My query is not listed here, what to do?
              </Accordion.Header>
              <Accordion.Body>
                Kindly mail us at{" "}
                <a href="mailto:refer@paymeindia.in">refer@paymeindia.in</a> we
                will revert you within 24 hours.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  leadWallet,
  getUserDetails,
})(Faq);
