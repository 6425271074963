import { React, useState } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../img/Logo.png";
import "./Sidebar.css";
import { updateMpin } from "../Redux/ApiCall/action";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 5000,
};


const Resetmpin = (props) => {
  
  const [mpin, setMpin] = useState("");
  const [confirmMpin, setConfirmMpin] = useState("");
  const [errormpin, setErrormpin] = useState("");
  const[errmpinmatch,setErrmpinmatch]=useState("");
  const [sameMpinerr,setSameMpinerr]=useState("")
  const  notify = (err) => {
    return toast.error(err, {...options})
  }

  const handleChangeMpin = () => {
    if(mpin===""){
      notify("Enter 6 digit MPIN");
      return;
    }
  let token =props.phone_number.User.token;
 
  if(errormpin!=="")
  {
    return;
  }
  if(mpin!==confirmMpin)
  {
    setErrmpinmatch("Password  not matched");
  return;
  }
 let data = {
   mpin
 }


  props
  .updateMpin(data,token)
  .then((res)=>{
    if(res.status===200)
    {
      props.history.push({
        pathname: '/',
        state: { detail: res.data.message }
      });
    }
    setSameMpinerr(res.data.error);
  })
  .catch((error) => {
    let err = error.response.data.error
  
    notify(err)
    // Notification(error)
  });
 
  };

  return (
    <>
        <div className='blue-bg'>
            <Card className='loginScreen'>
                <Card.Body className='loginScreenPadding'>
              <Link to="/">
                {" "}
                <Card.Img
                  style={{
                      width:'auto',
                      height: 37,
                      margin: "auto",
                  }}
                  src={logo}
                  alt="Paymeindia"
                />
              </Link>
                    <Card.Title  className='labelTitleBox'>
                Reset Password
              </Card.Title>
                    <label htmlFor="userid" className='labelText'>
                New Password
              </label>
              <input
               maxLength={6}
               className="inputField"
                style={{ marginBottom: "2px" }}
                id="password"
                name="password"
                type="password"
                placeholder="New Password"
                value={mpin}
                onChange={(e) => {
                  setErrmpinmatch("")
                  if (e.target.value.length !== 6) {
                    setErrormpin("Password Must be 6 digits");
                  }
                  else{
                    setErrormpin("");
                  }
                  setMpin(e.target.value);
                }}
              />
              {errormpin?<span style={{color:"red"}}>{errormpin}</span>:null}
                    <Card.Text  className='labelText' style={{paddingTop:0, fontWeight: 100, textAlign:'left'}}>
                Password must be 6 digits
              </Card.Text>
                    <label htmlFor="userid" className='labelText'>
                Confirm Password
              </label>
              <input
              maxLength={6}
              className="inputField"
                id="password"
                name="password"
                type="password"
                placeholder="Confirm Password"
                value={confirmMpin}
                onChange={(e) => {
                  setConfirmMpin(e.target.value);
                  setErrmpinmatch("");
                  
                }}
              />
              {errmpinmatch?<span style={{color:"red"}}>{errmpinmatch}</span>:null}
              {sameMpinerr ?<span style={{color:"red"}}>{sameMpinerr}</span>:null}
              <Button
                onClick={handleChangeMpin}
                className="btnNormal btn btn-primary"
              >
                Change Password
              </Button>
            </Card.Body>
          </Card>
        </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { updateMpin })(Resetmpin);
