import {React,useState} from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import WithdrawalIcon from "@material-ui/icons/Redeem";
import GroupIcon from "@material-ui/icons/Group";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import HelpIcon from "@material-ui/icons/Help";
import PolicyIcon from "@material-ui/icons/Policy";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {slide as Menu} from 'react-burger-menu'


const Side = (props) => {


    return (
        <>
            <Menu>
                <div className="sidebar_page">
                    <div className="dashboard_option">
                        <Link to="/dashboard">
                            <DashboardIcon className="dashboard_icon" />
                            <span className="dashboard_span" >Dashboard</span>
                        </Link>
                        <Link to="/leads">
                            <GroupIcon className="dashboard_icon" style={{top: "55px"}}/>
                            <span className="dashboard_span" style={{top:"58px"}}>Leads</span>
                        </Link>
                        <Link to="/transactions">
                            <EqualizerIcon className="dashboard_icon" style={{top: "111px"}}/>
                            <span className="dashboard_span" style={{top: "115px"}}>Earnings</span>
                        </Link>
                        <Link to="/withdrawal">
                            <WithdrawalIcon className="dashboard_icon" style={{top: "171px"}}/>
                            <span className="dashboard_span" style={{top: "175px"}}>Withdrawal</span></Link>

                        <Link to="/faq">
                            <HelpIcon className="dashboard_icon" style={{top: "225px"}}/>
                            <span className="dashboard_span" style={{top: "229px"}}>Help</span>
                        </Link>
                        <Link to="/terms-conditions">
                            <PolicyIcon className="dashboard_icon" style={{top: "275px"}}/>
                            <span className="dashboard_span" style={{top: "279px"}}>T & C</span>
                        </Link>
                    </div>
                </div>
            </Menu>
            <div className="sidebar_page">
                <div className="dashboard_option">
                    <Link to="/dashboard">
                        <DashboardIcon className="dashboard_icon" />
                        <span className="dashboard_span" >Dashboard</span>
                    </Link>
                    <Link to="/leads">
                        <GroupIcon className="dashboard_icon" style={{top: "55px"}}/>
                        <span className="dashboard_span" style={{top: "58px"}}>Leads</span>
                    </Link>
                    <Link to="/transactions">
                        <EqualizerIcon className="dashboard_icon" style={{top: "111px"}}/>
                        <span className="dashboard_span" style={{top: "115px"}}>Earnings</span>
                    </Link>
                    <Link to="/withdrawal">
                        <WithdrawalIcon className="dashboard_icon" style={{top: "171px"}}/>
                        <span className="dashboard_span" style={{top: "175px"}}>Withdrawal</span></Link>

                    <Link to="/faq">
                        <HelpIcon className="dashboard_icon" style={{top: "225px"}}/>
                        <span className="dashboard_span" style={{top: "229px"}}>Help</span>
                    </Link>
                    <Link to="/terms-conditions">
                        <PolicyIcon className="dashboard_icon" style={{top: "275px"}}/>
                        <span className="dashboard_span" style={{top: "279px"}}>T & C</span>
                    </Link>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(Side);
