import { USER_TOKEN,PHONE_NUMBER ,USER_PROFILE} from "../type";
const intialState = {
  isUserLoggedIn: false,
};

const user = (state = intialState, action) => {
  switch (action.type) {
    case PHONE_NUMBER:
      return {
        ...state,
        phone_number: action.payload.phone_number,
      };

    case USER_TOKEN:
      return {
        ...state,
        User: action.payload,
        isUserLoggedIn: true,
      };
      case USER_PROFILE:
        return {
          ...state,
          User: action.payload,
          isUserLoggedIn: true,
        };
    default:
      return state;
  }
};

export default user;
